.container{max-width: 910px;width: 100%; margin: 0 auto;}
.ui_header {
    background-color: transparent;
    position: absolute;
    z-index: 3;
    width: 100%;
  }
.menu{
    max-width: 910px;
    width: 100%;  
    padding: 20px 10px 25px;  
    margin: 0 auto;  
}
.bob-logo-navbar {display: none; background-color:#FF0055; border-radius: 5px; margin-right: 10px; margin-bottom: 10px;}
.menu .logo{display: block;
    max-width: max-content;
    float: left;}
.menu .logo a{font-size: 30px;
    color: #fff;
    font-weight: 700;
    line-height: 30px;
    align-items: center;
    display: flex;
    margin-top: -4px;
    }
.menu .top-nav{float: right;margin-top: 8px;margin-right: 8px;}
.menu .top-nav ul li{margin-right: 40px;}
.menu .top-nav ul li a.ui_active {
    color: #FFFFFF;
    border-color: #FFFFFF;
}
.menu .top-nav ul li a {
    color: var(--color-D9D9D9); 
    font-size: 14px;
    line-height: 12px;
    padding-bottom: 2px;
    font-family: var(--font-Poppins-Bold);
    border-bottom: solid 3px transparent;
    
}
.menu .top-nav ul li a:hover {border-color: var(--color-FF0055);}
.menu .button {position: relative;display: block;float: left;margin-top: -5px;}
.menu .button button{background-color: rgb(15, 14, 14);width: 36px;height: 36px;border-radius: 50%;margin-right: 10px;float: left;margin-top: -7px; cursor: pointer;}
.menu .button .button-wrapper {margin-right: 12px !important; margin-top: -7px;}
.menu .button button img{margin: 0 auto;width: 20px;height: 20px;}
.menu .button button .blue{display: none;}
.menu .button button .white{display: none;}
.display_smile {display: none !important;}
.scroll-container { position: fixed;
    top: 10px; 
    right: 10px; 
    display: flex;
    flex-direction: column;
    }
