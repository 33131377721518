.banner-upload2 {
    width: 46px !important;
    height: 42px;
    margin: 90 auto;
}
.banner-upload2-add2 {
    width: 100%;
    height: 100%;
}
.portada-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
}


.perfil-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 109px;
    height: 146px;
    overflow: hidden;
    background-color: #DDE2E5;
    border-radius: 15px;
    margin-right: 0px;

}