.img-banner {
    max-width: 100% !important;
    width: 100%;
    height: 100%;
    max-height: 100%;
}

.banner-anuncio {
    margin-top: -100px;
    margin-left: 100px;
    color:#5028C6;
}