.selection_day .carrusel {
  padding-bottom: 296px;
}

.rueda {
  position: absolute;
  width: 100%;
  left: 10px;
  display: flex;
  background: transparent;
  max-width: 580px;
  height: 580px;
  margin-top: -30px;

}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
