.buscador-container {
    width: 310px !important;
    height: 40px;
    border-radius: 20px;
    border: 2px solid #5F7CEB; 
    background-color: #F0F4F8; 
    transition: background-color 0.3s;
   
  }
  
  
  .buscador-container.show .text-container {
    background-color: #E5E5E5; /* Fondo más grisaceo cuando se activa */
  }
  
  #dropdown-buscador {
    background-color: transparent;
    margin-left: 30px;
    border: none;
  }
  
  .text-container {
    display: inline-block;
    align-items: center;
    justify-content: center;
    font-weight: bold; 

  }