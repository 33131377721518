

.ui_header_buttons.switch01 {
    position: relative;
    width: 50px; 
    height: 30px; 
    top: 10px;
}
  
  .ui_header_buttons.switch01 input {
    appearance: none;
    width: 100%;
    height: 100%;
    background-color:#FF0055; 
    border-radius: 15px; 
    position: absolute;
    cursor: pointer;
  }
  
  .ui_header_buttons.switch01 input:checked {
    background-color:#5028c6; 
  }
  
  .ui_header_buttons.switch01 input::before {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px; 
    margin-top: 2px;
    margin-left: 2px;
    background-image: url('../../assets/img/interna/comunidad_solicitudes/smilelove.svg'); 
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%; 
    transition: transform 0.3s ease-in-out; 
    background-color:white;
    border: solid 1px white;
  }
  
  .ui_header_buttons.switch01 input:checked::before {
    background-image: url('../../assets/img/svg/icon_mobile/off-default.png'); 
    margin-left: 3px;
    transform: translateX(20px); 
}
  