/* .banner-anuncio{display: none;} */
.img-banner {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    display: block;
}

.item {
max-width: 100%;}
.img-banner .item {max-width: 100%;}

.banner-anuncio {
    margin-top: -230px;
}
