/* style.css */
.custom-dropdown {
    cursor: pointer;
  }
  
  .dropdown-options-container {
    max-height: 925px;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 10px;
  }
  
  .dropdown-option {
    display: flex;
    align-items: center;
  }
  
  .styled-checkbox {
    appearance: none;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border: 2px solid #DDE2E5;
    border-radius: 4px;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
  }
  
  /* Cuando está seleccionado */
  .styled-checkbox:checked {
    border-color: #5F36DA;
    background-color: #5F36DA;
  }
  
  .styled-checkbox:checked::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 4px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .dropdown-option label {
    color: #495057;
    font-family: var(--font-Poppins-Medium);
    font-size: 14;


  }
  

  .modal-content {max-width: 415px;}
  .modal-header {
    display: flex;
    flex-direction: column; /* Alinea los elementos uno debajo del otro */
    align-items: center; /* Centra horizontalmente los elementos */
    height: 70px; /* Altura del header */
    padding: 1rem; /* Espaciado alrededor del contenido */
    border-bottom: 2px solid #DDE2E5; /* Línea divisoria en la parte inferior */
    box-shadow: none; /* Elimina el sombreado */
  }
  
  .modal-header h3 {
    color: #5028C6; /* Color del título */
    margin: 0; /* Elimina el margen por defecto */
    font-family: var(--font-Poppins-SemiBold);
    font-size: 16px;
  }
  
  .modal-header h6 {
    color: #333333; /* Color del subtítulo */
    margin: 0; /* Elimina el margen por defecto */
    font-family: var(--font-Poppins-Medium);
    font-size: 12px;
  }

  .modal-footer {justify-content: center;}

  .group-date.historia {align-items: center; display: flex; flex-direction: row;}

  /* Asegura que el texto esté en una línea y en mayúsculas */
.selected-options-text {
  white-space: nowrap; /* Evita saltos de línea */
  text-transform: capitalize; /* Pone en mayúscula la primera letra de cada palabra */
  overflow: hidden; /* Oculta cualquier texto que desborde */
  text-overflow: ellipsis; /* Añade '...' si el texto es demasiado largo */

}
.dropdown-icon {
  width: 24px; /* Ajusta el ancho según sea necesario */
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 30px; /* Ajusta la distancia del borde derecho */
}
.dropdown-icon svg {
  color: #5F36DA;
}

