.comment-box .ql-toolbar { display: none;
}


.comment-box .ql-container {
  min-height: 84px; 
  height: auto;
  margin-bottom: 0;
  border-radius: 20px 20px 0 0;
  background-color: #DDE2E5;
  border: none;
}

.comment-box .ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: auto; 
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.spoiler {
  background-color: #525252; /* Color de fondo para el spoiler */
  color: transparent; /* Texto transparente para ocultar el spoiler */
}
.spoiler-icon {
  cursor: pointer;
}
.comment-box .editor-container .ql-container  {
  background-color: #DDE2E5 !important ;
}
.comment-box .comment-form .ql-container {
  background-color: #DDE2E5 !important;
}
.reply-editor .ql-container {
  background-color: #DDE2E5 !important;
}
.comments-list .ql-container {
  background-color: #f6f8fa;
}


.comment-box {
  background-color: #f6f8fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.comment-form {
  align-items: center;
  gap: 7px;
}
.submit-edit-container{
  margin-left: auto;
  align-items: center;
  display: flex;
  gap: 15px;
  margin-right: 10px;
}
.cancel-edit {
  border: none ;
  background-color: transparent;
  color: #6610f2 !important;
  font-family: var(--font-Poppins-Medium);
}
.save-edit {
  border: solid 1px #FF0055;
  background-color: transparent;
  color: #FF0055;
  border-radius: 20px;
  padding: 3px 6px;
  font-family: var(--font-Poppins-Medium);

}
.eliminate-comment {
  background-color: #FF0055;
  color: #fff;
  border-radius: 20px;
  padding: 3px 6px;
  font-family: var(--font-Poppins-Medium);

}
.submit-comment {
  margin-left: auto;
  background-color: #FF0055 !important;
  border-radius: 25px !important;
  width: 101px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content:center;
  margin-right: 10px;}

.comment-box .react-quill {
  flex: 1;
}
.comment-box .react-quill .ql-editor {
  font-family: var(--font-Poppins-Medium);
  font-size: 12px;
  color: #000000;
}
.button-group {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}
.button-group-reply {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}
.reply {margin-top: 40px; margin-left: 40px;}
.button-container {
  display: flex;
  align-items: center;
  gap: 7px;
  background-color: #DDE2E5;
  height: 50px;
  border-radius: 0 0 20px 20px;
  border-top: 1px solid #cbd1d2;
  padding-left: 15px;
}
button-container img {
  cursor: pointer;
  width: 26px;
  height: 26px;
}
.view-icon {
  height: 20px !important;
  width: 20px !important;
}
.comment-form button {
  cursor: pointer;
  padding: 8px;
  border: none;
  color: #fff;
  border-radius: 4px;
}
.sticker-button {
  padding: 0;
  width: 26px;
  height: 26px;
  background-image: url('../../assets/img/svg/smile.svg'); 
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.comments-list {
  padding: 10px;
  border-top: 1px solid #e1e4e8;
}
.comment {
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: #f6f8fa;
  box-shadow: 0 1px 3px rgba(27, 31, 35, 0.12);
}

.comment-section {
  margin-bottom: 30px;
}


.right-a {float: right;font-family: var(--font-Poppins-Medium); cursor: pointer;
}
.left-a {
  font-family: var(--font-Poppins-Medium);
}
.left-a span:focus {
  font-family: var(--font-Poppins-Bold);
}
.left-a span:active {
  font-family: var(--font-Poppins-Bold);
}
.left-aligned-header {
  display: flex; 
  align-items: center; 
  justify-content: flex-start; 
  width: 100%;
}

.left-aligned-header .avatar {
  margin-right: 10px; }

.left-aligned-header .comment-header-info {
  display: flex;
  flex-direction: column; 
}

.left-aligned-header .username {
  font-weight: bold; 
}

.left-aligned-header .rating {
  display: flex;
  align-items: center;
}

.left-aligned-header .rating .star-icon {
  margin-right: 4px; 

}

.star-icon { color: #3B82F6}

.rating-container {display: flex; gap: 5px;}

.rating-container span {color: #7048E8}
.reply-header {
  margin-bottom: 16px; 
  display: flex;
  flex-direction: column;
}

.comment-header {
  margin-bottom: 16px; 
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}
.reply-header-info {
  display: flex;
  flex-direction: column;
}

.comment-header-info {
  display: flex;
  flex-direction: column;
}

.comment-header-info .username {
  font-weight: bold;
  font-size: 14px;
  color: #24292e;
}
.reply-header-info .username {
  font-weight: bold;
  font-size: 14px;
  color: #24292e;
}

.comment-header-info .rating {
  font-size: 12px;
  color: #586069;
}
.reply-header-info .rating {
  font-size: 12px;
  color: #586069;
}

.comment-header .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.reply-header .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.comment-text {
  overflow: hidden;
  margin-left: 30px;
}

.reply-text {
  margin-left: 30px;
}

.comment-text .ql-container {
  height: auto; 
  overflow: hidden; 
}

.comment-text .ql-editor {
  padding: 0;
  line-height: 1.5;
  height: auto; 

  font-family: var(--font-Poppins-Medium);
  font-size: 12px;
  color: #000000;
}

.comment img, .reply img {
  max-width: 99px;
  max-height: 76px;
  object-fit: cover; 
  display: inline;
}

.comment .emoji-image {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  display: block;
}
.emoji-image {
  width: 24px; 
  height: 24px; 
  vertical-align: middle; 
}

.reply-form .ql-container {
  height: 80px;
  margin-bottom: 10px;
}

.replies {
  padding-left: 40px;
  margin-top: 10px;
  border-left: 2px solid #e1e4e8;
}

.comment-box .ql-editor img[src*="custom_emojis/"] {
  width: 1.3em;
  display: inline;
  margin-left: 3px;
  margin-right: 3px; 
}

.comment-box .ql-editor img[src*="custom_stickers/"] {
  width: 60px;
  height: 60px;
  display: inline;
}

.comment-box .ql-editor p {display: inline;
  font-family: var(--font-Poppins-Medium);
  }
 .comment-box .editor-container .ql-editor p {
  color: #828282;
  font-family: var(--font-Poppins-Medium);

 }


.sticker-image {
  display: inline-block; 
  max-width: 100px;
  max-height: 100px;
}

.alerta-container {
  margin-top: 16px; 
  width: 100%;
  display: flex;
  justify-content: center; 
}

.alerta-gris {
  background-color: #f8d7da; 
  color: #721c24; 
  padding: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.alerta-gris img {
  margin-right: 8px; 
}

.detalle-comentarios {
  display: flex;
  margin-left: auto;  
}
.detalle-comentarios span {
  font-family: var(--font-Poppins-Medium);
  color: #C0C0C0;
}
.detalle-reply {
  display: flex;
  margin-left: auto;
}
.detalle-reply span {
  font-family: var(--font-Poppins-Medium);
  color: #C0C0C0;
}

.responder-reportar {
  font-family: var(--font-Poppins-Medium);
  color: #C0C0C0;
}

.images-preview {
  display: flex;
  gap: 10px; 
}

.images-preview img {
  max-width: 100px !important; 
  max-height: 76px;
  width: 100px;
  height: 76px;
  border-radius: 10px;
  margin-top: 10px;
}
.calification-container {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}
.calification-container span {
  font-family: var(--font-Poppins-Medium);
  font-size: 12px;
  color: #000000;
}

.cat-avatar {
  margin-right: 10px;
}
.custom-emoji {
  width: 1.2em; 
  height: 1.2em;
  margin-left: 5px;
  margin-right: 5px;
}

.edit-icon {
  cursor: pointer;
  width: 26px !important;
  height: 26px !important;
  margin-right: 5px;

}
.trash-icon {
  margin-left: 15px;
  cursor: pointer;
  height: 20px !important;
  width: 15px !important;
}
.trash-icon:hover::after {
  content: 'Remover foto';
  z-index: 10;
}

.nested-replies {
  margin-top: 30px;
}
