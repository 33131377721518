.tabs .nav-tabs .nav-link{
    font-family: var(--font-Poppins-Medium);
    font-size: 14px;
    padding: 10px 18px;
    border-bottom: 3px solid transparent;color: var(--bs-body-color);
    border: none; 
} 
.tabs .nav-tabs .nav-link.active{
    border-bottom: 3px solid var(--color-FF0055);
    font-family: var(--font-Poppins-Bold);} 

    
      

   


