.modal-section {
    border-bottom: 1px solid #ccc;
    padding: 10px 25px ;
    display: flex;
    align-items: left;
  }
  {/*.modal-content {width: 491px;} */}
  .modal-body {border-radius: 25px !important;}
  .modal-section h5 {color:#5028C6; font-family: var(--font-Poppins-SemiBold); font-size: 14px;}
  .modal-section h5 .contenido-original {font-size: 16px;}
  .modal-section h6 {font-family: var(--font-Poppins-SemiBoldPoppins); font-size: 14px; color: #333333;}
  .modal-section p {font-family: var(--font-Poppins-SemiBoldPoppins); font-size: 12px; color: #333333;}
 
  
  .checkbox-input {
    margin-right: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .checkbox-input:checked {
    background-color: #007bff;
  }
  
  .checkbox-input[type="checkbox"]::before {
    content: "";
    width: 100%;
    height: 100%;
    display: inline-block;
  }
  
  .checkbox-input[type="checkbox"]:checked::before {
    content: "\2713";
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 20px;
  }
  
  .custom-modal-header {
    border-bottom: none; 
    background-color: #fff; 
    box-shadow: none; 
  }
/* style.css */
.custom-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff0055; 
  border: none; 
  border-radius: 50%; 
  width: 32px; 
  height: 32px; 
  cursor: pointer; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.custom-close-button svg {
  width: 20px; 
  height: 20px; 
  stroke: #ffffff; 
  stroke-width: 1;
}


  